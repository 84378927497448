import { AjaxPromise } from '../shared/ajax_utilities';

export class BaseGraph {
    GRIDSTACK_NAME;

    constructor(gridstackName) {
        if(!gridstackName) {
            console.warn('No grid stack name provided failed to initialize graph class.');
            return;
        }
        this.GRIDSTACK_NAME = gridstackName;
        app.DASHBOARD_CORE.GRAPH_CLASSES[this.GRIDSTACK_NAME] = this;
    }

    setup() {
        try {
            app.DASHBOARD_CORE.setupGraph(this.GRIDSTACK_NAME, app.DASHBOARD_CORE.GRAPH_DATA[this.GRIDSTACK_NAME]);
        } catch(err) {
            console.warn(`Failed to setup graph ${this.GRIDSTACK_NAME}.`, err);
        }
    }

    async update() {
        try {
            const allowed = {};
            allowed[this.GRIDSTACK_NAME] = { checked: true };
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX_DASHBOARD}graphs`,
                data: {
                    dashboard: app.DASHBOARD_CORE.DASHBOARD,
                    allowed: allowed,
                },
                method: 'POST',
            });

            const $layoutGrid = app.DOM.gridstack.filter(`#${this.GRIDSTACK_NAME}`);
            app.DASHBOARD_CORE.GRAPH_DATA[this.GRIDSTACK_NAME] = res.data[this.GRIDSTACK_NAME];
            Object.keys(Chart.instances).forEach((key) => {
                const chart = Chart.instances[key];
                if($layoutGrid.find('canvas').is($(chart.canvas))) {
                    chart.config.data = app.DASHBOARD_CORE.GRAPH_DATA[this.GRIDSTACK_NAME];
                }
            });
        } catch(err) {
            console.log(err);
        }
    }

    postSetup($parent) {}

    getChartJsOptions(options, data) {
        return options;
    }
}